export const faqs = () => {
  const faq = [
    {
      id: 0,
      title: "Is getting a tattoo or piercing safe?",
      body: "Yes, when done by a professional and experienced artist using proper sterilization and hygiene practices. Our artists are licensed and follow strict safety guidelines.",
    },
    {
      id: 1,
      title: "Does getting a tattoo or piercing hurt?",
      body: "Pain levels vary from person to person and depend on the location and size of the tattoo or piercing. Our artists will do their best to make the experience as comfortable as possible.",
    },
    {
      id: 2,
      title: "How much does a tattoo or piercing cost?",
      body: "Prices vary based on the complexity and size of the design, as well as the type of piercing. We offer competitive pricing, and you can get a personalized quote by visiting our studio.",
    },
    {
      id: 3,
      title: "Do I need to make an appointment or can I walk in?",
      body: "We recommend making an appointment to secure your spot, especially for custom tattoos or complex piercings. Walk-ins are welcome, but availability may be limited.",
    },
    {
      id: 4,
      title: "Can I bring my own design for a tattoo?",
      body: "Absolutely! We encourage clients to bring their own designs or reference images. Our artists can work with you to create a custom piece that suits your vision.",
    },
    {
      id: 5,
      title: "What type of ink and equipment do you use?",
      body: "We use high-quality, professional-grade tattoo ink and sterile equipment. Our needles are single-use and disposable to ensure maximum safety.",
    },
    {
      id: 6,
      title: "How long does it take for a tattoo to heal?",
      body: "Tattoo healing time varies but typically takes around 2-4 weeks. Our artists will provide you with aftercare instructions to help with the healing process.",
    },
    {
      id: 7,
      title: "Can I get a tattoo if I'm under 18 years old?",
      body: "In accordance with local regulations, we require clients to be at least 18 years old for tattoos. For majority of standard piercings you will need to be at least 16 years old, and may require parental guidance.",
    },
    {
      id: 8,
      title: "What aftercare do I need for my piercing?",
      body: "Our piercers will provide detailed aftercare instructions to ensure proper healing. It's essential to follow these instructions to avoid complications.",
    },
    {
      id: 9,
      title: "Can I change my jewelry after getting a piercing?",
      body: "It's best to leave the initial jewelry in place until the piercing is fully healed. Once healed, our piercers can assist you in changing your jewelry.",
    },
    {
      id: 10,
      title: "Do you offer tattoo cover-ups or removals?",
      body: "Yes, we provide tattoo cover-up services, and we can also discuss options for tattoo removal if desired.",
    },
    {
      id: 11,
      title:
        "Is there anything I should avoid before getting a tattoo or piercing?",
      body: "It's best to avoid alcohol and blood-thinning medications before your appointment. Additionally, get a good night's sleep and eat a meal before coming in.",
    },
  ];
  return faq;
};

export const cookies = () => {
  const cookie = [
    {
      id: 0,
      title: "Cookie Policy of Mr Greg Tattoo",
      body: `This document informs Users about the technologies that help this Website to achieve the purposes described below. Such technologies allow the Owner to access and store information (for example by using a Cookie) or use resources (for example by running a script) on a User’s device as they interact with this Website. \n\n For simplicity, all such technologies are defined as "Trackers" within this document – unless there is a reason to differentiate.For example, while Cookies can be used on both web and mobile browsers, it would be inaccurate to talk about Cookies in the context of mobile apps as they are a browser-based Tracker. For this reason, within this document, the term Cookies is only used where it is specifically meant to indicate that particular type of Tracker. \n\n Some of the purposes for which Trackers are used may also require the User's consent. Whenever consent is given, it can be freely withdrawn at any time following the instructions provided in this document. \n\n This Website uses Trackers managed directly by the Owner (so-called “first-party” Trackers) and Trackers that enable services provided by a third-party (so-called “third-party” Trackers). Unless otherwise specified within this document, third-party providers may access the Trackers managed by them. \n\n The validity and expiration periods of Cookies and other similar Trackers may vary depending on the lifetime set by the Owner or the relevant provider. Some of them expire upon termination of the User’s browsing session. In addition to what’s specified in the descriptions within each of the categories below, Users may find more precise and updated information regarding lifetime specification as well as any other relevant information – such as the presence of other Trackers - in the linked privacy policies of the respective third-party providers or by contacting the Owner.`,
    },
    {
      id: 1,
      title:
        "Activities strictly necessary for the operation of this Website and delivery of the Service",
      body: "This Website uses so-called “technical” Cookies and other similar Trackers to carry out activities that are strictly necessary for the operation or delivery of the Service.",
    },
    {
      id: 2,
      title: "SPAM protection",
      body: "This type of service analyses the traffic of this Website, potentially containing Users' Personal Data, with the purpose of filtering it from parts of traffic, messages and content that are recognised as SPAM \n\n Google reCAPTCHA \n\n Google reCAPTCHA is a SPAM protection service provided by Google LLC or by Google Ireland Limited, depending on how the Owner manages the Data processing. \n\n The use of reCAPTCHA is subject to the Google privacy policy and terms of use. \n\n Personal Data processed: answers to questions, clicks, keypress events, motion sensor events, mouse movements, scroll position, touch events, Trackers and Usage Data.",
    },
    {
      id: 3,
      title: "Other activities involving the use of Trackers",
      body: "This Website uses Trackers to provide a personalised user experience by improving the quality of preference management options, and by enabling interaction with external networks and platforms.",
    },
    {
      id: 4,
      title: "Displaying content from external platforms",
      body: "This type of service allows you to view content hosted on external platforms directly from the pages of this Website and interact with them.\n\n This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.",
    },
    {
      id: 5,
      title: "Font Awesome (Fonticons, Inc. )",
      body: "Font Awesome is a typeface visualisation service provided by Fonticons, Inc. that allows this Website to incorporate content of this kind on its pages. \n\n Personal Data processed: Trackers and Usage Data. \n\n Place of processing: United States – Privacy Policy.",
    },
    {
      id: 6,
      title: "Google Fonts",
      body: "Google Fonts is a typeface visualisation service provided by Google LLC or by Google Ireland Limited, depending on how the Owner manages the Data processing, that allows this Website to incorporate content of this kind on its pages. \n\n Personal Data processed: Trackers and Usage Data.\n\n Place of processing: United States – Privacy Policy; Ireland – Privacy Policy.",
    },
    {
      id: 7,
      title: "Measurement & Google Analytics",
      body: "This Website uses Trackers to measure traffic and analyse User behaviour with the goal of improving the Service. \n\n The services contained in this section enable the Owner to monitor and analyse web traffic and can be used to keep track of User behaviour. \n\n Google Analytics is a web analysis service provided by Google LLC or by Google Ireland Limited, depending on how the Owner manages the Data processing, (“Google”). Google utilises the Data collected to track and examine the use of this Website, to prepare reports on its activities and share them with other Google services \n\n Google may use the Data collected to contextualise and personalise the ads of its own advertising network. \n\n Google Analytics 4 is a web analysis service provided by Google LLC or by Google Ireland Limited, depending on how the Owner manages the Data processing, (“Google”). Google utilizes the Data collected to track and examine the use of this Website, to prepare reports on its activities and share them with other Google services. \n\n Google may use the Data collected to contextualize and personalize the ads of its own advertising network. \n\n In Google Analytics 4, IP addresses are used at collection time and then discarded before Data is logged in any data center or server. Users can learn more by consulting Google’s official documentation. \n\n Personal Data processed: browser information, city, device information, session statistics, Trackers and Usage Data.",
    },
    {
      id: 8,
      title: "How to manage preferences and provide or withdraw consent",
      body: "There are various ways to manage Tracker related preferences and to provide and withdraw consent, where relevant: \n\n Users can manage preferences related to Trackers from directly within their own device settings, for example, by preventing the use or storage of Trackers. \n\n Additionally, whenever the use of Trackers is based on consent, Users can provide or withdraw such consent by setting their preferences within the cookie notice or by updating such preferences accordingly via the relevant consent-preferences widget, if available. \n\n It is also possible, via relevant browser or device features, to delete previously stored Trackers, including those used to remember the User’s initial consent. \n\n Other Trackers in the browser’s local memory may be cleared by deleting the browsing history. \n\n With regard to any third-party Trackers, Users can manage their preferences and withdraw their consent via the related opt-out link (where provided), by using the means indicated in the third party's privacy policy, or by contacting the third party. \n\n Users may also manage certain categories of Trackers used on mobile apps by opting out through relevant device settings such as the device advertising settings for mobile devices, or tracking settings in general (Users may open the device settings and look for the relevant setting). \n\n Users are free to decide whether or not to grant consent. However, please note that Trackers help this Website to provide a better experience and advanced functionalities to Users (in line with the purposes outlined in this document). Therefore, in the absence of the User's consent, the Owner may be unable to provide related features.",
    },
    {
      id: 9,
      title: "Owner and Data Controller",
      body: "Mr Greg Tattoo \n 53 Easter RD \n Edinburgh \n EH7 5PL \n United Kingdom \n\n Owner contact email: \n mrgregtattoo@gmail.com \n\n Since the use of third-party Trackers through this Website cannot be fully controlled by the Owner, any specific references to third-party Trackers are to be considered indicative. In order to obtain complete information, Users are kindly requested to consult the privacy policies of the respective third-party services listed in this document. \n\n Given the objective complexity surrounding tracking technologies, Users are encouraged to contact the Owner should they wish to receive any further information on the use of such technologies by this Website.",
    },
    {
      id: 10,
      title: "Personal Data (or Data)",
      body: "Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.",
    },
    {
      id: 11,
      title: "Usage Data",
      body: "Information collected automatically through this Website (or third-party services employed in this Website), which can include: the IP addresses or domain names of the computers utilised by the Users who use this Website, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilised to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilised by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.",
    },
    {
      id: 12,
      title: "User",
      body: "The individual using this Website who, unless otherwise specified, coincides with the Data Subject.",
    },
    {
      id: 13,
      title: "Data Subject",
      body: "The natural person to whom the Personal Data refers.",
    },
    {
      id: 14,
      title: "Data Processor (or Data Supervisor)",
      body: "The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.",
    },
    {
      id: 15,
      title: "Data Controller (or Owner)",
      body: "The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Website. The Data Controller, unless otherwise specified, is the Owner of this Website.",
    },
    // {
    //   id: 16,
    //   title: "",
    //   body: "",
    // },
  ];
  return cookie;
};
export const privacy = () => {
  const term = [
    {
      id: 0,
      title: "Analytics",
      body: "Google Analytics \n Personal Data: Tracker; Usage Data \n\n Google Analytics 4 \n Personal Data: browser information; city; device information; session statistics; Trackers; Usage Data",
    },
    {
      id: 1,
      title: "Contacting the User",
      body: "Contact form \n Personal Data: email address; first name; last name",
    },
    {
      id: 2,
      title: "Hosting and backend infrastructure",
      body: "Google Firebase \n Personal Data: various types of Data as specified in the privacy policy of the service",
    },
    {
      id: 3,
      title: "SPAM protection",
      body: "Google reCAPTCHA \n Personal Data: answers to questions; clicks; keypress events; motion sensor events; mouse movements; scroll position; touch events; Trackers; Usage Data",
    },
    {
      id: 4,
      title: "Backup saving and management",
      body: "Google \n Personal Data: various types of Data as specified in the privacy policy of the service",
    },
    {
      id: 5,
      title: "Displaying content from external platforms",
      body: "Font Awesome and Google Fonts \n Personal Data: Trackers; Usage Data",
    },
    {
      id: 6,
      title: "Managing contacts and sending messages",
      body: "Google Cloud Services \n Personal Data: email address, full name, contact number",
    },
    {
      id: 7,
      title: "Contact information",
      body: "Mr Greg Tattoo \n 53 Easter Rd \n Edinburgh \n EH7 5PL \n United Kingdom \n\n Owner contact email: \n mrgregtattoo@gmail.com",
    },
  ];
  return term;
};
export const termsCon = () => {
  const tc = [
    {
      id: 0,
      title: "What the User should know at a glance",
      body: "Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.",
    },
    {
      id: 1,
      title: "TERMS OF USE",
      body: "Unless otherwise specified, the terms of use detailed in this section apply generally when using this Website. \n\n Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document \n\n By using this Website, Users confirm to meet the following requirements: \n There are no restrictions for Users in terms of being Consumers or Business Users;",
    },
    {
      id: 2,
      title: "Account registration",
      body: "To use the Service Users may register or create a User account, providing all required data or information in a complete and truthful manner. \n Users may also use the Service without registering or creating a User account, however, this may cause limited availability of certain features or functions. \n\n Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Website. \n\n By registering, Users agree to be fully responsible for all activities that occur under their username and password. \n Users are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.",
    },
    {
      id: 3,
      title: "Conditions for account registration",
      body: "Registration of User accounts on this Website is subject to the conditions outlined below. By registering, Users agree to meet such conditions. \n\n Accounts registered by bots or any other automated methods are not permitted. \n\n Unless otherwise specified, each User must register only one account. \n\n Unless explicitly permitted, a User account may not be shared with other persons.",
    },
    {
      id: 4,
      title: "Account termination",
      body: "Users can terminate their account and stop using the Service at any time by doing the following: \n\n By directly contacting the Owner at the contact details provided in this document.",
    },
    {
      id: 5,
      title: "Account suspension and deletion",
      body: "The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts which it deems inappropriate, offencive or in violation of these Terms. \n\n The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement. \n\n The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.",
    },
    {
      id: 6,
      title: "Content on this Website",
      body: "Unless where otherwise specified or clearly recognisable, all content available on this Website is owned or provided by the Owner or its licensors. \n\n The Owner undertakes its utmost effort to ensure that the content provided on this Website infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result. \n In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.",
    },
    {
      id: 7,
      title: "Rights regarding content on this Website - All rights reserved",
      body: "The Owner holds and reserves all intellectual property rights for any such content. \n\n Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service. \n\n In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicence, edit, transfer/assign to third parties or create derivative works from the content available on this Website, nor allow any third party to do so through the User or their device, even without the User's knowledge. \n\n Where explicitly stated on this Website, the User may download, copy and/or share some content available through this Website for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented. \n\n Any applicable statutory limitation or exception to copyright shall stay unaffected.",
    },
    {
      id: 8,
      title: "Access to external resources",
      body: "Through this Website Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability. \n\n Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.",
    },
    {
      id: 9,
      title: "Acceptable use",
      body: "This Website and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law. \n\n Users are solely responsible for making sure that their use of this Website and/or the Service violates no applicable law, regulations or third-party rights. \n\n Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Website or the Service, terminating contracts, reporting any misconduct performed through this Website or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities: \n violate laws, regulations and/or these Terms; \n infringe any third-party rights; \n considerably impair the Owner’s legitimate interests; \n offend the Owner or any third party.",
    },
  ];
  return tc;
};
