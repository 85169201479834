import React from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { useDB } from "../../../contexts/DBContext";
import styles from "./Footer.module.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  // const { pdf } = useDB();
  const location = useLocation();
  const navigate = useNavigate();

  function scrollToSection(id) {
    const el = document.getElementById(id);
    el?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }

  return (
    <Container fluid className={styles.footerWrap}>
      <Container className={styles.sectionWrap}>
        {/* <Row className={styles.innerWrap}>
          <Col className={styles.wrap}>
            <div className={styles.left}>
              <Link onClick={() => scrollToSection("artists")}>Artists</Link>
              <Link onClick={() => scrollToSection("contact")}>FAQ</Link>
              <Link onClick={() => scrollToSection("contact")}>Contact</Link>
            </div>
            <div className={styles.right}></div>
          </Col>
        </Row> */}
        <Row className={styles.top}>
          <Col className={styles.wrap}>
            <div className={styles.inner}>
              <div
                onClick={() => {
                  navigate("/");
                  scrollToSection("home");
                }}
                className={styles.imgWrap}
              >
                <img
                  src={require("../../../images/logo-white.png")}
                  alt="Mr Greg Tattoo logo"
                />
              </div>
              <p>53 Easter Rd, Edinburgh EH7 5PL</p>
            </div>
            <div className={styles.inner}>
              <h5>Contact & Openings</h5>
              <div className={styles.content}>
                <p>Phone: 0131 629 1183</p>
                <p>Email: mrgregtattoo@gmail.com</p>
                <p>Tuesday - Saturday / 10:00 - 18:00.</p>
              </div>
            </div>
            {/* <div className={styles.inner}>
              <h5>Downloads</h5>
              <div className={styles.content}>
                {pdf &&
                  pdf.map((item) => (
                    <Link key={item.id} target="_blank" to={item.url}>
                      {item.title}
                    </Link>
                  ))}
              </div>
            </div> */}
          </Col>
        </Row>
        <Row className={styles.bottom}>
          <Col className={styles.wrap}>
            <div className={styles.inner}>
              <p>© 2010-{new Date().getFullYear()} COPYRIGHT MR GREG TATTOO</p>
            </div>
            <div className={styles.inner}>
              <Link to="/terms/cookies" state={{ previousLocation: location }}>
                COOKIES
              </Link>
              <Link
                to="/terms/privacy-policy"
                state={{ previousLocation: location }}
              >
                PRIVACY POLICY
              </Link>
              <Link
                to="/terms/terms-and-conditions"
                state={{ previousLocation: location }}
              >
                TERMS & CONDITIONS
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
