import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./NavBar.module.scss";
import { Link, useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const [activeBackground, setActiveBackground] = useState(false);
  const [hideNavbar, setHideNavbar] = useState(false);

  useEffect(() => {
    let currentScroll = 0;
    let screenHeight = window.innerHeight;
    const onScroll = () => {
      currentScroll = window.pageYOffset;
      if (currentScroll > screenHeight) {
        setActiveBackground(true);
      } else {
        setActiveBackground(false);
      }

      if (currentScroll > 120) {
        setHideNavbar(true);
      } else {
        setHideNavbar(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  function scrollToSection(id) {
    const el = document.getElementById(id);
    el?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  }

  return (
    <Container
      fluid
      className={`${styles.navbarWrapper} ${
        activeBackground && styles.activeBackground
      } ${hideNavbar && styles.hideNavbar}`}
    >
      <Container className={styles.sectionWrap}>
        <Row className={styles.row}>
          <Col className={styles.wrap}>
            <div
              onClick={() => {
                navigate("/");
                scrollToSection("home");
              }}
              className={styles.logoWrap}
            >
              <img
                src={require("../../../images/logo-black.png")}
                alt="Mr Greg Tattoo logo"
              />
            </div>
            <div className={styles.linksWrap}>
              <Link onClick={() => scrollToSection("artists")}>Artists</Link>
              <Link onClick={() => scrollToSection("contact")}>Contact</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default NavBar;
