import React, { useEffect, useRef } from "react";
import styles from "./Modal.module.scss";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ArtistLayout } from "../pages/artist/ArtistLayout";
import { Terms } from "../pages/terms/Terms";

const Modal = () => {
  const modalRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const abortController = new AbortController();
    const observerRefValue = modalRef.current;
    if (observerRefValue) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "initial";
      abortController.abort();
    };
  }, []);

  const renderModal = (id) => {
    switch (id) {
      case "artist":
        return <ArtistLayout />;
      case `terms`:
        return <Terms />;
      case id:
        return (
          <div
            style={{
              minHeight: "800px",
              maxWidth: "1200px",
              width: "100%",
              backgroundColor: "whitesmoke",
            }}
          >
            {id}
          </div>
        );
      default:
        <></>;
    }
  };
  return (
    <div
      ref={modalRef}
      onClick={() => navigate("/", { state: { from: location } })}
      className={styles.modalWrapper}
    >
      <div className={id === "artist" ? styles.overlay : null}></div>
      <div onClick={(e) => e.stopPropagation()} className={`${styles.inner}`}>
        {renderModal(id)}
      </div>
    </div>
  );
};

export default Modal;
